import React, { useEffect, useState } from "react";
import "../styles/GetAConsultation.css";
import emailjs from "emailjs-com";

const GetAConsultation = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    phone: "",
    email: "",
    message: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        "https://api.lifesignify.com/api/consultations",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ formData }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      const { redirectUrl, transactionId, paymentStatus } = responseData;

      window.location.href = redirectUrl;

      await emailjs.send(
        "service_rny5mbw",
        "template_jwqizlf",
        {
          from_name: formData.firstName,
          phone: formData.phone,
          email: formData.email,
          message: formData.message,
          transaction_id: transactionId,
        },
        "HO3SlQI_fcJJn2AbH"
      );

      // Clear the form
      setFormData({
        firstName: "",
        phone: "",
        email: "",
        message: "",
      });
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div className="getAConsultationContainer">
      <div className="getAConsultationHeader">
        <div className="getAConsultationHeaderTop">
          <div className="rectangle"></div>
          <p>Get a Consultation</p>
        </div>
        <div className="getAConsultationHeaderBottom">
          <h1>Let's Get Started!</h1>
        </div>
      </div>
      <div className="getAConsultationFormContainer">
        <p>
          Please tell us how we can help you. A consultation fee is required.
          Fields marked with * are required. Your information is protected by
          our privacy policy.
        </p>
        <form className="getAConsultationForm" onSubmit={handleSubmit}>
          <input
            type="text"
            name="firstName"
            placeholder="Full Name*"
            className="inputField"
            value={formData.firstName}
            onChange={handleChange}
            required
          />
          <input
            type="tel"
            name="phone"
            placeholder="Phone*"
            className="inputField"
            value={formData.phone}
            onChange={handleChange}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Email*"
            className="inputField"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <textarea
            name="message"
            placeholder="Message*"
            className="messageField"
            value={formData.message}
            onChange={handleChange}
            required
          />
          <button type="submit" className="submitButton">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default GetAConsultation;
