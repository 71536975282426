import React, { useEffect, useContext, useState } from "react";
import "../styles/Orders.css";
import { SlCalender } from "react-icons/sl";
import { AuthContext } from "../AuthContext";
import axios from "axios";

const Orders = () => {
  const { userData } = useContext(AuthContext);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [expandedOrder, setExpandedOrder] = useState(null);

  console.log(orders);

  const fetchProductDetails = async (itemType, productId) => {
    try {
      let response;
      if (itemType === "Book") {
        response = await axios.get(
          `https://api.lifesignify.com/api/books/${productId}`
        );
      } else if (itemType === "Course") {
        response = await axios.get(
          `https://api.lifesignify.com/api/courses/${productId}`
        );
      } else if (itemType === "Dhyanam") {
        response = await axios.get(
          `https://api.lifesignify.com/api/dhyanam/${productId}`
        );
      }
      return response.data; // Returning the product data
    } catch (error) {
      console.error(`Error fetching ${itemType} data:`, error);
      return null;
    }
  };

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `https://api.lifesignify.com/api/orders/user/${localStorage.getItem(
            "userId"
          )}`
        );
        const data = await response.json();

        const ordersWithProductData = await Promise.all(
          data.orders.map(async (order) => {
            const productDetails = await Promise.all(
              order.productId.map(async (product) => {
                // Fetch product details based on itemType
                const productData = await fetchProductDetails(
                  product.itemType,
                  product.id
                );
                return productData;
              })
            );
            return { ...order, productDetails };
          })
        );
        setOrders(ordersWithProductData);
      } catch (error) {
        console.error("Error fetching orders:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const toggleOrderDetails = (orderId) => {
    if (expandedOrder === orderId) {
      setExpandedOrder(null); // If the same order is clicked, close the details
    } else {
      setExpandedOrder(orderId); // Otherwise, expand the order details
    }
  };

  return (
    <div className="ordersContainer">
      {loading ? (
        <p>Loading...</p>
      ) : orders.length > 0 ? (
        <div className="orderMainRenderDiv">
          {orders.map(
            (
              order // order.paymentStatus === "Paid" &&
            ) => (
              <div key={order._id} className="orderItem">
                <div className="orderDetails">
                  <h3>Order ID: {order?._id}</h3>
                  <p>Payment Status: {order?.paymentStatus}</p>
                  <p>Order Status: {order?.orderStatus}</p>
                  <p>Total Amount: ₹{parseFloat(order?.totalAmount) / 100}</p>
                  <button onClick={() => toggleOrderDetails(order?._id)}>
                    {expandedOrder === order?._id
                      ? "Hide Order Details"
                      : "View Order Details"}
                  </button>

                  {expandedOrder === order?._id && (
                    <div className="orderExpandedDetails">
                      <h4>Order Placed</h4>
                      <p>{order?.createdAt}</p>
                      <h4>Transaction Id</h4>
                      <p>{order?.transactionId}</p>
                      <h4>Shipping Address</h4>
                      <p>
                        {`${order?.shippingAddress.fullName}, ${order?.shippingAddress.mobile}, ${order?.shippingAddress.street}, ${order?.shippingAddress.city}, ${order?.shippingAddress.state}, ${order?.shippingAddress.country}, ${order?.shippingAddress.pincode}`}
                      </p>
                    </div>
                  )}

                  <div className="orderProducts">
                    {order.productDetails.map((product, index) => (
                      <div key={index} className="productDetail">
                        <img
                          src={product?.image || product?.imageUrl}
                          alt={product?.title || product?.name}
                          className="productImage"
                        />
                        <h4>{product?.title || product?.name}</h4>
                        <p>₹{product?.price}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      ) : (
        <div className="ordersMain">
          <div className="ordersMainLeft">
            <SlCalender size={24} color="rgb(0, 122, 209)" />
            <p>No paid orders found.</p>
          </div>
          <div className="ordersMainRight">Browse products</div>
        </div>
      )}
    </div>
  );
};

export default Orders;
