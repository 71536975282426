// import React, { useEffect, useState } from "react";
// import "../styles/Testimonial.css";
// import axios from "axios";

// const Testimonial = () => {
//   const [testimonial, setTestimonials] = useState([]);
//   useEffect(() => {
//     window.scrollTo(0, 0);
//     fetchTestimonials();
//   }, []);

//   const fetchTestimonials = async () => {
//     try {
//       const response = await axios.get(
//         "https://api.lifesignify.com/api/testimonial"
//       );
//       setTestimonials(response.data);
//     } catch (error) {
//       console.error("Failed to fetch testimonials", error);
//     }
//   };
//   return (
//     <div className="testimonial-container">
//       <h1 className="testimonial-title">What Our Clients Say</h1>
//       <div className="testimonial-grid">
//         {testimonial.map((testimonial) => (
//           <div key={testimonial._id} className="testimonial-card">
//             <img
//               src={testimonial.avatar}
//               alt={testimonial.name}
//               className="testimonial-avatar"
//             />
//             <p className="testimonial-quote">"{testimonial.quote}"</p>
//             <h3 className="testimonial-name">{testimonial.name}</h3>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Testimonial;

import React, { useEffect, useState } from "react";
import "../styles/Testimonial.css";
import axios from "axios";

const Testimonial = () => {
  const [testimonial, setTestimonials] = useState([]);
  const [activeVideo, setActiveVideo] = useState(null); // State to manage active video card

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchTestimonials();
  }, []);

  const fetchTestimonials = async () => {
    try {
      const response = await axios.get(
        "https://api.lifesignify.com/api/testimonial"
      );
      setTestimonials(response.data);
    } catch (error) {
      console.error("Failed to fetch testimonials", error);
    }
  };

  const handleVideoToggle = (id) => {
    if (activeVideo === id) {
      setActiveVideo(null); // Close the video if it's already open
    } else {
      setActiveVideo(id); // Open the video
    }
  };

  console.log(testimonial);
  return (
    <div className="testimonial-container">
      <h1 className="testimonial-title">What Our Clients Say</h1>
      <div className="testimonial-grid">
        {testimonial.map((testimonial) => (
          <div key={testimonial._id} className="testimonial-card">
            <img
              src={testimonial.avatar}
              alt={testimonial.name}
              className="testimonial-avatar"
            />
            <p className="testimonial-quote">"{testimonial.quote}"</p>
            <h3 className="testimonial-name">{testimonial.name}</h3>

            {activeVideo !== testimonial._id && (
              <button
                onClick={() => handleVideoToggle(testimonial._id)}
                className="play-video-btn"
              >
                Play Video
              </button>
            )}

            {activeVideo === testimonial._id && (
              <div className="video-container">
                <iframe
                  width="100%"
                  height="315"
                  src={`https://drive.google.com/file/d/${
                    testimonial.videoLink.match(/d\/(.*?)(\/|$)/)[1]
                  }/preview`}
                  allow="autoplay; fullscreen"
                  style={{ borderRadius: 5 }}
                ></iframe>
                <button
                  onClick={() => handleVideoToggle(testimonial._id)}
                  className="close-video-btn"
                >
                  Close Video
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonial;
